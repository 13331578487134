import CenteredFrame from "../layout/CenteredFrame";
import Frame from "../layout/Frame";
import educationItems from "../../assets/education/educationItems.json";
import EducationItem from "./EducationItem";
import classes from "./Education.module.scss";
import React from "react";
import { IEducationItem } from "./EducationTypes";

const Education: React.FC = () => {
  const items: IEducationItem[] = educationItems;
  return (
    <CenteredFrame className={classes.educationFrame}>
      <Frame>
        <p className={classes.title}>EDUCATION</p>
        {items.map((entry) => (
          <EducationItem key={entry.id} education={entry} />
        ))}
      </Frame>
    </CenteredFrame>
  );
};

export default Education;
