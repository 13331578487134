import CenteredFrame from "../layout/CenteredFrame";
import Frame from "../layout/Frame";
import LearnItem from "./LearnItem";
import classes from "./Learning.module.scss";
import learningItems from "../../assets/learning/learningItems.json";
import React from "react";
import { ILearnItem } from "./LearnTypes";

const Learning: React.FC = () => {
  const items: ILearnItem[] = learningItems;
  return (
    <CenteredFrame className={classes.learningFrame}>
      <Frame>
        <p className={classes.centeredTitle}>Currently learning / reading</p>
        <div className={classes.list}>
          {items.map((item) => (
            <LearnItem key={item.id} item={item} />
          ))}
        </div>
      </Frame>
    </CenteredFrame>
  );
};

export default Learning;
