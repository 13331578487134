import MainNavigation from "../components/MainNavigation";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import React from "react";

const resolveErrorMessage = (error: unknown): string => {
  if (!isRouteErrorResponse(error)) {
    return "Unexpected error occurred!";
  }
  const data = error.data;
  return `Error status: ${error.status} message: ${data ? data.message : "General error!"}`;
};

const ErrorPage: React.FC = () => {
  const error = useRouteError();
  const message = resolveErrorMessage(error);

  return (
    <>
      <MainNavigation />
      <h1>Error occurred</h1>
      <p>{message}</p>
    </>
  );
};

export default ErrorPage;
