import { NavLink } from "react-router-dom";
import classes from "./MainNavigation.module.scss";
import { resolveLinkActiveStyle } from "../utils/style-utils";
import React from "react";

const MainNavigation: React.FC = () => {
  return (
    <header>
      <nav className={classes.navigation}>
        <ul className={classes.list}>
          <li className={classes.item}>
            <NavLink to="/" end className={resolveLinkActiveStyle}>
              HOME
            </NavLink>
          </li>

          <li className={classes.item}>
            <NavLink to="/learning" className={resolveLinkActiveStyle}>
              LEARNING
            </NavLink>
          </li>

          {/*<li className={classes.item}>
                    <NavLink to="/portfolio"
                             className={resolveLinkActiveStyle}>
                        PORTFOLIO
                    </NavLink>
                </li>*/}
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
