import ExperienceItem from "./ExperienceItem";
import CenteredFrame from "../layout/CenteredFrame";
import Frame from "../layout/Frame";
import classes from "./Experience.module.scss";
import experienceItems from "../../assets/experience/experienceItems.json";
import React from "react";
import { IExperienceItem } from "./ExperienceTypes";

const Experience: React.FC = () => {
  const items: IExperienceItem[] = experienceItems;
  return (
    <CenteredFrame className={classes.experienceFrame}>
      <Frame>
        <p className={classes.title}>EXPERIENCE</p>
        {items.map((entry) => (
          <ExperienceItem key={entry.id} experience={entry} />
        ))}
      </Frame>
    </CenteredFrame>
  );
};

export default Experience;
