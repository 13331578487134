import classes from "./LearnItem.module.scss";
import { ILearnItemResource } from "./LearnTypes";
import React from "react";

export function addImage(imageUri: string | undefined) {
  if (imageUri) {
    const images = require.context("../../assets/learning/", true);
    const image = images(`./${imageUri}`);
    return <img className={classes.image} src={image} alt="Avatar"></img>;
  }
}

export function addLink(url: string | undefined, label: string | undefined) {
  if (url && label) {
    return (
      <a
        className={classes.subText}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
      </a>
    );
  }
}

export function addImageLink(
  imageUri: string | undefined,
  url: string | undefined,
) {
  if (imageUri && url) {
    let image = imageUri;
    if (!imageUri.startsWith("http")) {
      const images = require.context("../../assets/learning/", true);
      image = images(`./${imageUri}`);
    }
    return (
      <a href={url} target="_blank" rel="noreferrer">
        <img className={classes.image} src={image} alt="Avatar"></img>
      </a>
    );
  }
}

export function resolveContent(content: ILearnItemResource) {
  const type = content.type;
  if (type === "image") {
    return addImage(content.value);
  } else if (type === "link") {
    return addLink(content.value, content.label);
  } else if (type === "image-link") {
    return addImageLink(content.image, content.url);
  }
}
