import CenteredFrame from "./layout/CenteredFrame";
import Frame from "./layout/Frame";
import classes from "./Footer.module.scss";
import React from "react";

const Footer: React.FC = () => {
  return (
    <CenteredFrame className={classes.footerFrame}>
      <Frame>
        <p>© 2023 - 2024 Martin Klein</p>
      </Frame>
    </CenteredFrame>
  );
};

export default Footer;
