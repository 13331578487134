import Frame from "../layout/Frame";
import classes from "./AboutMeBanner.module.scss";
import CenteredFrame from "../layout/CenteredFrame";
import React from "react";

const AboutMeBanner: React.FC = () => {
  return (
    <CenteredFrame>
      <Frame className={classes.textContentFrame}>
        <p>
          I<span className={classes.specialText}>'</span>M
        </p>
        <p>MARTIN KLEIN</p>
      </Frame>
    </CenteredFrame>
  );
};

export default AboutMeBanner;
