import classes from "./ExperienceItemDescription.module.scss";
import Frame from "../layout/Frame";
import React, { useMemo } from "react";
import { IExperienceItem } from "./ExperienceTypes";

export interface Props {
  experience: IExperienceItem;
}

const ExperienceItemDescription: React.FC<Props> = (props) => {
  const experience = props.experience;
  const bulletPoints = experience.bulletPoints;
  const renderedBulletPoints = useMemo(() => {
    if (bulletPoints && bulletPoints.length !== 0) {
      return (
        <ul className={classes.bulletPoints}>
          {bulletPoints.map((item) => (
            <li key={item}>
              <p className={classes.smallText}>{item}</p>
            </li>
          ))}
        </ul>
      );
    }
  }, [bulletPoints]);

  return (
    <Frame className={classes.frame}>
      <p className={classes.descriptionText}>{experience.description}</p>
      {renderedBulletPoints}
    </Frame>
  );
};

export default ExperienceItemDescription;
