import CenteredFrame from "../layout/CenteredFrame";
import Frame from "../layout/Frame";
import learnedItems from "../../assets/learning/learnedItems.json";
import LearnItem from "./LearnItem";
import classes from "./Learned.module.scss";
import React from "react";
import { ILearnItem } from "./LearnTypes";

const Learned: React.FC = () => {
  const items: ILearnItem[] = learnedItems;
  return (
    <CenteredFrame className={classes.learnedFrame}>
      <Frame>
        <p className={classes.centeredTitle}>What I already touched</p>
        <div className={classes.list}>
          {items.map((item) => (
            <LearnItem key={item.id} item={item} />
          ))}
        </div>
      </Frame>
    </CenteredFrame>
  );
};

export default Learned;
