import Frame from "../layout/Frame";
import classes from "./EducationItemDescription.module.scss";
import React from "react";
import { IEducationItem } from "./EducationTypes";

export interface Props {
  education: IEducationItem;
}

const EducationItemDescription: React.FC<Props> = (props) => {
  const education = props.education;

  return (
    <Frame className={classes.frame}>
      <p className={classes.descriptionText}>{education.description}</p>
      <p className={classes.smallText}>
        Field of study: {education.fieldOfStudy}
      </p>
    </Frame>
  );
};

export default EducationItemDescription;
