import ExperienceItemInfo from "./ExperienceItemInfo";
import ExperienceItemDescription from "./ExperienceItemDescription";
import classes from "./ExperienceItem.module.scss";
import Frame from "../layout/Frame";
import React from "react";
import { IExperienceItem } from "./ExperienceTypes";

export interface Props {
  experience: IExperienceItem;
}

const ExperienceItem: React.FC<Props> = (props) => {
  const experience = props.experience;

  return (
    <Frame className={classes.itemFrame}>
      <ExperienceItemInfo experience={experience} />
      <ExperienceItemDescription experience={experience} />
    </Frame>
  );
};

export default ExperienceItem;
