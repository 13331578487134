import Learning from "../components/learning/Learning";
import Learned from "../components/learning/Learned";
import Courses from "../components/learning/Courses";
import Footer from "../components/Footer";
import React from "react";

const LearningPage: React.FC = () => {
  return (
    <>
      <Learning />
      <Courses />
      <Learned />
      <Footer />
    </>
  );
};

export default LearningPage;
