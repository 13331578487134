import Frame from "../layout/Frame";
import classes from "./EducationItemInfo.module.scss";
import React from "react";
import { IEducationItem } from "./EducationTypes";

export interface Props {
  education: IEducationItem;
}

const EducationItemInfo: React.FC<Props> = (props) => {
  const education = props.education;

  return (
    <Frame className={classes.frame}>
      <p className={classes.text}>{education.name}</p>
      <p className={classes.subText}>{education.institution}</p>
      <p className={classes.subText}>{education.date}</p>
    </Frame>
  );
};

export default EducationItemInfo;
