import classes from "./ImageBanner.module.scss";
import Frame from "../layout/Frame";
import CenteredFrame from "../layout/CenteredFrame";
import React from "react";

const ImageBanner: React.FC = () => {
  const bannerImage = require("../../assets/banner-image.png");

  return (
    <CenteredFrame className={classes.frame}>
      <Frame className={classes.imageParent}>
        <img className={classes.image} src={bannerImage} alt="Avatar"></img>
      </Frame>
    </CenteredFrame>
  );
};

export default ImageBanner;
