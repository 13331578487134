import Frame from "../layout/Frame";
import EducationItemInfo from "./EducationItemInfo";
import EducationItemDescription from "./EducationItemDescription";
import classes from "./EducationItem.module.scss";
import React from "react";
import { IEducationItem } from "./EducationTypes";

export interface Props {
  education: IEducationItem;
}

const EducationItem: React.FC<Props> = (props) => {
  const education = props.education;

  return (
    <Frame className={classes.itemFrame}>
      <EducationItemInfo education={education} />
      <EducationItemDescription education={education} />
    </Frame>
  );
};

export default EducationItem;
