import classes from "./LearnItem.module.scss";
import Frame from "../layout/Frame";
import React from "react";
import { ILearnItem } from "./LearnTypes";
import { resolveContent } from "./LearnItemUtils";

export interface Props {
  alternativeStyle?: boolean;
  item: ILearnItem;
}

const LearnItem: React.FC<Props> = (props) => {
  const alternativeStyle = props.alternativeStyle;
  const item = props.item;

  const title = item.title;
  const subTitle = item.subTitle;
  const resources = item.resources;

  return (
    <Frame
      className={
        alternativeStyle
          ? classes.learningItemFrameAlt
          : classes.learningItemFrame
      }
    >
      {title && (
        <p className={classes.text + " " + classes.smallParagraph}>{title}</p>
      )}
      {subTitle && <p className={classes.smallParagraph}>{subTitle}</p>}
      <ul className={classes.list}>
        {resources.map((item) => (
          <li key={item.id}>{resolveContent(item)}</li>
        ))}
      </ul>
    </Frame>
  );
};

export default LearnItem;
