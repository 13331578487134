import Frame from "./Frame";
import classes from "./CenteredFrame.module.scss";
import React from "react";

const CenteredFrame: React.FC<any> = (props) => {
  return (
    <Frame className={`${classes.centered} ${props.className}`}>
      {props.children}
    </Frame>
  );
};

export default CenteredFrame;
