import CenteredFrame from "../layout/CenteredFrame";
import classes from "./Courses.module.scss";
import Frame from "../layout/Frame";
import courseItems from "../../assets/learning/courseItems.json";
import LearnItem from "./LearnItem";
import React from "react";
import { ILearnItem } from "./LearnTypes";

const Courses: React.FC = () => {
  const items: ILearnItem[] = courseItems;
  return (
    <CenteredFrame className={classes.coursesFrame}>
      <Frame>
        <p className={classes.centeredTitle}>Courses</p>
        <div className={classes.list}>
          {items.map((item) => (
            <LearnItem key={item.id} item={item} alternativeStyle={true} />
          ))}
        </div>
      </Frame>
    </CenteredFrame>
  );
};

export default Courses;
