import Banner from "../components/banner/Banner";
import AboutMe from "../components/AboutMe";
import Experience from "../components/experience/Experience";
import Education from "../components/education/Education";
import Footer from "../components/Footer";
import React from "react";

const HomePage: React.FC = () => {
  return (
    <>
      <Banner />
      <AboutMe />
      <Experience />
      <Education />
      <Footer />
    </>
  );
};

export default HomePage;
