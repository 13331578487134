import Frame from "../layout/Frame";
import classes from "./Portfolio.module.scss";
import CenteredFrame from "../layout/CenteredFrame";
import React from "react";

const Portfolio: React.FC = () => {
  return (
    <CenteredFrame>
      <Frame>
        <p className={classes.text}>Page under construction 👷</p>
      </Frame>
    </CenteredFrame>
  );
};

export default Portfolio;
