import Frame from "../layout/Frame";
import classes from "./ExperienceItemInfo.module.scss";
import React from "react";
import { IExperienceItem } from "./ExperienceTypes";

export interface Props {
  experience: IExperienceItem;
}

const ExperienceItemInfo: React.FC<Props> = (props) => {
  const experience = props.experience;

  return (
    <Frame className={classes.frame}>
      <p className={classes.text}>{experience.name}</p>
      <p className={classes.subText}>{experience.company}</p>
      <p className={classes.subText}>{experience.date}</p>
    </Frame>
  );
};

export default ExperienceItemInfo;
