import CenteredFrame from "./layout/CenteredFrame";
import classes from "./AboutMe.module.scss";
import Frame from "./layout/Frame";
import aboutMeItems from "../assets/about-me/about-me.json";
import React from "react";

const AboutMe: React.FC = () => {
  return (
    <CenteredFrame className={classes.aboutMeFrame}>
      <Frame>
        <p className={classes.title}>ABOUT ME</p>
        {aboutMeItems.map((entry) => (
          <p key={entry.id} className={classes.descriptionText}>
            {entry.text}
          </p>
        ))}
      </Frame>
    </CenteredFrame>
  );
};

export default AboutMe;
