import classes from "./Frame.module.scss";
import React from "react";

const Frame: React.FC<any> = (props) => {
  return (
    <div className={`${classes.content} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Frame;
