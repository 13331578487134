import Frame from "../layout/Frame";
import classes from "./Banner.module.scss";
import AboutMeBanner from "./AboutMeBanner";
import ImageBanner from "./ImageBanner";
import React from "react";

const Banner: React.FC = () => {
  return (
    <Frame className={classes.bannerFrame}>
      <AboutMeBanner />
      <ImageBanner />
    </Frame>
  );
};

export default Banner;
